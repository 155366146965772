import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_BUDGET_CATEGORIES, GET_BUDGET_UNITS } from '@atom/graph/budget';
import { Progress } from '@atom/mui';
import {
  BudgetCategoriesConnection,
  BudgetCategoriesConnectionInput,
  BudgetUnitsConnection,
  BudgetUnitsConnectionInput,
} from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailCategories from './categoryTable/BudgetDetailCategories';
import BudgetDetailChildUnits from './unitsTable/BudgetDetailChildUnits';
import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailOverview from './BudgetDetailOverview';

import './budgetDetail.css';

const styles = {
  container: {
    marginTop: '90px',
  },
  floatingSpinner: {
    position: 'fixed',
    width: '100%',
    top: '15rem',
    zIndex: 10,
  },
};

const BudgetDetailUnit = () => {
  const {
    budget,
    parentBudgetUnit,
    childBudgetUnits,
    setChildBudgetUnits,
    budgetCategories,
    setBudgetCategories,
    categoryIds,
    budgetItemTemplateNames,
    excludeZeroBudgetItems,
    loadingParentUnit,
  } = useContext(BudgetDetailContext);

  const [getBudgetUnits, { loading: loadingBudgetUnits }] = useLazyQuery<
    { budgetUnits: BudgetUnitsConnection },
    { input: BudgetUnitsConnectionInput }
  >(GET_BUDGET_UNITS, {
    fetchPolicy: 'network-only',
    onCompleted: response =>
      setChildBudgetUnits(response?.budgetUnits.budgetUnits),
  });

  const [
    getBudgetCategories,
    { loading: loadingBudgetCategories },
  ] = useLazyQuery<
    { budgetCategories: BudgetCategoriesConnection },
    { input: BudgetCategoriesConnectionInput }
  >(GET_BUDGET_CATEGORIES, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      const categoriesData = response?.budgetCategories?.budgetCategories;
      setBudgetCategories(categoriesData);
    },
  });

  // Get display categories or units
  // - update with parentBudgetUnit and filter changes
  const callUpdateChildren = () => {
    if (!isNilOrEmpty(parentBudgetUnit)) {
      if (parentBudgetUnit.hasBudgetItems === false) {
        setBudgetCategories(null);
        setChildBudgetUnits(null);
        getBudgetUnits({
          variables: {
            input: {
              budgetId: budget.id,
              parentBudgetUnitId: parentBudgetUnit.id,
              categoryIds,
              budgetItemTemplateNames,
            },
          },
        });
      } else {
        setChildBudgetUnits(null);
        getBudgetCategories({
          variables: {
            input: {
              budgetId: budget.id,
              budgetUnitId: parentBudgetUnit.id,
              categoryIds,
              budgetItemTemplateNames,
              excludeZeroBudgetItems,
            },
          },
        });
      }
    }
  };
  useEffect(callUpdateChildren, [parentBudgetUnit, excludeZeroBudgetItems]);

  const budgetCategoriesView = !R.isNil(budgetCategories) ? (
    <BudgetDetailCategories getBudgetCategories={getBudgetCategories} />
  ) : (
    <></>
  );

  const childTableView = R.isNil(childBudgetUnits) ? (
    budgetCategoriesView
  ) : loadingBudgetUnits ? (
    <Progress />
  ) : (
    <BudgetDetailChildUnits />
  );

  const showFloatingSpinner =
    (loadingParentUnit || !R.isNil(budgetCategories)) &&
    loadingBudgetCategories;

  return (
    <div style={styles.container}>
      <BudgetDetailOverview />
      {showFloatingSpinner && <Progress style={styles.floatingSpinner} />}
      {childTableView}
    </div>
  );
};

export default BudgetDetailUnit;
