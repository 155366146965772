import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ClickAwayListener } from '@mui/material';
import * as R from 'ramda';

// @ts-ignore
import compare from '@atom/components/common/svgIcons/compare.svg';
import { GET_BUDGET_SUMMARIES } from '@atom/graph/budget';
import { Popper } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';
import {
  BudgetSummary,
  BudgetSummaryConnection,
  BudgetSummaryConnectionInput,
} from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailOverviewComparison from './BudgetDetailOverviewComparison';

import './budgetDetail.css';

const styles = {
  popper: {
    backgroundColor: colors.neutral.white,
    boxShadow: layout.muiDropdownBoxShadow,
    zIndex: 100,
  },
  totalsRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1.5rem',
    gap: '1.5rem',
  },
  totalBudgetLabel: {
    fontSize: fonts.sm,
    color: colors.neutral.dim,
    paddingBottom: '0.5rem',
  },
  totalBudgetAmount: {
    fontSize: fonts.lg,
    color: colors.neutral.dark,
    fontWeight: '500',
    gap: '0.5rem',
  },
  heading: {
    fontSize: fonts.lg,
    padding: '0.75rem 0',
    fontWeight: '500',
  },
  compareIcon: {
    width: '1.25rem',
  },
  hoverArea: {
    display: 'inlineBlock',
    minWidth: '7rem',
  },
};

const BudgetDetailOverview = () => {
  const {
    parentBudgetUnit,
    comparisonBudgets,
    budgetOverviewSummaries,
    setBudgetOverviewSummaries,
    categoryIds,
    budgetItemTemplateNames,
    showTracking,
  } = useContext(BudgetDetailContext);
  const [open, setOpen] = useState<boolean>(false);
  const [hovering, setHovering] = useState<boolean>(false);

  const anchorRef = useRef();

  const [fetchSummaries, { loading: loadingSummaries }] = useLazyQuery<
    { budgetSummary: BudgetSummaryConnection },
    { input: BudgetSummaryConnectionInput }
  >(GET_BUDGET_SUMMARIES, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const summaries: BudgetSummary[] = R.pathOr(
        [],
        ['budgetSummary', 'budgetSummary'],
        data,
      );
      setBudgetOverviewSummaries(summaries);
    },
  });

  useEffect(() => {
    if (
      open &&
      !isNilOrEmpty(comparisonBudgets) &&
      !loadingSummaries &&
      isNilOrEmpty(budgetOverviewSummaries)
    ) {
      fetchSummaries({
        variables: {
          input: {
            budgetUnitId: parentBudgetUnit.id,
            budgetIds: R.pluck('id', comparisonBudgets),
            categoryIds,
            itemNames: budgetItemTemplateNames,
          },
        },
      });
    }
  }, [open]);

  const compareIcon = useMemo(
    () =>
      hovering || open ? (
        <img
          onClick={() => setOpen(!open)}
          styleName="clickable"
          src={compare}
          style={styles.compareIcon}
        />
      ) : null,
    [hovering, open],
  );

  const hoverStyle = useMemo(
    () => ({
      ...styles.hoverArea,
      ...((hovering || open) && { fontWeight: 800 }),
    }),
    [hovering, open],
  );

  return (
    <>
      <div style={styles.heading}>Overview</div>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div style={hoverStyle}>
          <div
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            ref={anchorRef}
            style={styles.totalsRow}
          >
            <div>
              <div style={styles.totalBudgetLabel}>Total Budget</div>
              <div style={styles.totalBudgetAmount}>
                {numberToLocaleString(parentBudgetUnit?.totalBudget)}
              </div>
            </div>
            {showTracking && (
              <>
                <div>
                  <div style={styles.totalBudgetLabel}>Total Actual</div>
                  <div style={styles.totalBudgetAmount}>
                    {numberToLocaleString(parentBudgetUnit?.actualBudget)}
                  </div>
                </div>
                <div>
                  <div style={styles.totalBudgetLabel}>Total Remaining</div>
                  <div style={styles.totalBudgetAmount}>
                    {numberToLocaleString(parentBudgetUnit?.remaining)}
                  </div>
                </div>
              </>
            )}
            {compareIcon}
          </div>

          <Popper
            open={open}
            anchorEl={anchorRef?.current}
            style={styles.popper}
            onClose={() => setOpen(false)}
            placement="bottom-start"
          >
            <BudgetDetailOverviewComparison
              setOpen={setOpen}
              loadingSummaries={loadingSummaries}
              budgetOverviewSummaries={budgetOverviewSummaries}
            />
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default BudgetDetailOverview;
