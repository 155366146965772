// This Component manages the editing state for all budget item rows
import React, { useContext, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import { UPDATE_BUDGET_ITEM } from '@atom/graph/budget';
import { ListTable, Snackbar } from '@atom/mui';
import {
  BudgetCategory,
  BudgetItem,
  BudgetItemUpdateInput,
} from '@atom/types/budget';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from '../BudgetDetailContext';
import {
  EditField,
  FIELD_KEYS,
  FIELD_NAMES,
  isReadOnly,
  ITEM_ROW_HEIGHT,
  MAX_ITEM_VALUE,
  MAX_ROWS_PER_CATEGORY,
} from '../budgetDetailUtils';

import BudgetDetailItemRow from './BudgetDetailItemRow';

import '../budgetDetail.css';

const { TableBody } = ListTable;

interface Props {
  budgetCategory: BudgetCategory;
  getBudgetCategories: (input: any) => void;
}

const BudgetDetailItemTable = ({
  budgetCategory,
  getBudgetCategories,
}: Props) => {
  const {
    budget,
    editingItem,
    setEditingItem,
    editingField,
    setEditingField,
    parentBudgetUnit,
    setParentBudgetUnit,
    categoryIds,
    budgetItemTemplateNames,
    excludeZeroBudgetItems,
  } = useContext(BudgetDetailContext);

  const [editValue, setEditValue] = useState<string>();
  const [previousEditValue, setPreviousEditValue] = useState(0);

  const [updateBudgetItem, { loading: loadingItemUpdate }] = useMutation<
    { budgetItemUpdate: BudgetItem },
    { input: BudgetItemUpdateInput }
  >(UPDATE_BUDGET_ITEM);

  const handleInputCellClick = (field: EditField, budgetItem: BudgetItem) => {
    if (hasRolePermissions(ROLE_SETS.ORG_ADMIN) && !isReadOnly(budget?.id)) {
      setEditValue(budgetItem[FIELD_KEYS[field]]);
      setEditingItem(budgetItem);
      setEditingField(field);
    }
  };

  const handleSaveField = async () => {
    if (editingField === 'VALUE' && parseFloat(editValue) < 0) {
      Snackbar.error({
        message: 'Please enter positive numbers for budget amounts.',
      });
      setEditingField(null);
      return;
    }

    if (
      editingField === 'VALUE' &&
      parseFloat(editValue) > MAX_ITEM_VALUE.VALUE
    ) {
      Snackbar.error({
        message: MAX_ITEM_VALUE.ERROR,
      });
      setEditingField(null);
      return;
    }

    // Do not save unchanged values
    if (editingItem[FIELD_KEYS[editingField]] === editValue) {
      setEditingField(null);
      return;
    }

    // Ensure all empty values passed to value field are 0
    const sanitizedValue =
      editingField === 'VALUE' && isNilOrEmpty(editValue) ? 0 : editValue;

    try {
      const updatedItemData = await updateBudgetItem({
        variables: {
          input: {
            budgetId: budget?.id,
            budgetItemId: editingItem?.id,
            budgetValue: editingItem.budgetValue,
            comment: editingItem.comment,
            [FIELD_KEYS[editingField]]: sanitizedValue,
          },
        },
      });

      // Re-calculate overview total when updating the value:
      // - calculating on FE to avoid page refresh between updates
      if (editingField === 'VALUE') {
        const newTotal =
          parentBudgetUnit.totalBudget +
          (parseFloat(editValue || '0') - previousEditValue);
        setParentBudgetUnit({ ...parentBudgetUnit, totalBudget: newTotal });
      }

      getBudgetCategories({
        variables: {
          input: {
            budgetId: budget?.id,
            budgetUnitId: parentBudgetUnit?.id,
            categoryIds,
            budgetItemTemplateNames,
            excludeZeroBudgetItems,
          },
        },
      });

      const getNewValue = () =>
        numberToLocaleString(
          updatedItemData.data.budgetItemUpdate[FIELD_KEYS[editingField]],
        );

      const messagePre = `Successfully updated ${FIELD_NAMES[editingField]} on ${editingItem.name}`;
      const messagePost =
        editingField === 'VALUE'
          ? `: from ${numberToLocaleString(
              editingItem.budgetValue,
            )} to ${getNewValue()}`
          : '';
      const message = messagePre + messagePost;

      Snackbar.info({
        message,
      });
    } catch (error) {
      const message =
        error.networkError.statusCode === 400 && editingField === 'VALUE'
          ? 'Please enter only numeric characters and periods in budget values.'
          : 'An error occurred while updating your budget. Please Try Again.';
      Snackbar.error({ message });
    }
    setEditingItem(null);
  };

  const handleKeyUp = event => {
    // detect and handle enter key to trigger save
    if (event.keyCode === 13) {
      handleSaveField();
    }
  };

  const handleInputValueChange = (event, prevTotal) => {
    setPreviousEditValue(prevTotal);
    setEditValue(event.target.value);
  };

  const sortedItems: BudgetItem[] = useMemo(() => {
    return R.sort(
      (itemA, itemB) => itemA.name.localeCompare(itemB.name),
      budgetCategory.budgetItems,
    );
  }, [budgetCategory.budgetItems]);

  const scrollViewHeightRems: string = useMemo(() => {
    const itemRows: number = R.min(sortedItems.length, MAX_ROWS_PER_CATEGORY);
    return `${ITEM_ROW_HEIGHT * itemRows}rem`;
  }, [sortedItems]);

  const itemList = (
    <Virtuoso
      style={{ height: scrollViewHeightRems }}
      data={sortedItems}
      itemContent={index => {
        const budgetItem = sortedItems[index];
        return (
          <BudgetDetailItemRow
            key={budgetItem?.id}
            budgetItem={budgetItem}
            budgetCategory={budgetCategory}
            handleInputCellClick={handleInputCellClick}
            loadingItemUpdate={loadingItemUpdate}
            handleInputValueChange={handleInputValueChange}
            handleKeyUp={handleKeyUp}
            handleSaveField={handleSaveField}
            editValue={editValue}
            setEditValue={setEditValue}
          />
        );
      }}
    />
  );

  return (
    <ListTable fullHeight={false}>
      <TableBody>{itemList}</TableBody>
    </ListTable>
  );
};

export default BudgetDetailItemTable;
