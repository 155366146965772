import React, {
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as R from 'ramda';

import PercentBar from '@atom/components/common/percentBar/PercentBar';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Icon, ListTable } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetCategory } from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailComparison from '../BudgetDetailComparison';
import BudgetDetailContext from '../BudgetDetailContext';
import { getComparisonIcon, ICON_COL_WIDTH } from '../budgetDetailUtils';

import '../budgetDetail.css';

const { TableRow, TableCell } = ListTable;

interface Props {
  budgetCategory: BudgetCategory;
  toggleRowOpen: (categoryId: string) => void;
}

const BudgetDetailCategoryRow = ({ budgetCategory, toggleRowOpen }: Props) => {
  const {
    budgetItemTemplateNames,
    budgetSummaries,
    comparisonBudgets,
    openComparisonRowId,
    setOpenComparisonRowId,
    expandedCategories,
    parentBudgetUnit,
    fetchSummaries,
    loadingSummaries,
    showTracking,
  } = useContext(BudgetDetailContext);

  const preferences = usePreferences();
  const hideCostType = R.pathOr(
    false,
    ['budgeting', 'hideCostType'],
    preferences,
  );

  const [hovering, setHovering] = useState<boolean>(false);

  const open: boolean = useMemo(
    () => openComparisonRowId === budgetCategory.id,
    [openComparisonRowId],
  );

  const anchorRef = useRef();

  useEffect(() => {
    if (open && !isNilOrEmpty(comparisonBudgets)) {
      fetchSummaries({
        variables: {
          input: {
            budgetUnitId: parentBudgetUnit.id,
            budgetIds: R.pluck('id', comparisonBudgets),
            categoryIds: [budgetCategory.id],
            itemNames: budgetItemTemplateNames,
          },
        },
      });
    }
  }, [open, comparisonBudgets]);

  const handleCompareClick = (event: MouseEvent) => {
    event.stopPropagation();
    setOpenComparisonRowId(open ? null : budgetCategory.id);
  };

  return (
    <>
      <TableRow
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        hovercolor={colors.neutral.fog}
        style={{
          backgroundColor: open ? colors.neutral.fog : colors.neutral.white,
        }}
      >
        <TableCell>
          <span
            onClick={() => toggleRowOpen(budgetCategory.id)}
            styleName="budget-table-cell category-name-cell clickable"
          >
            <Icon>
              {expandedCategories.has(budgetCategory.id)
                ? 'arrow_drop_down'
                : 'arrow_right'}
            </Icon>
            {budgetCategory.name}
          </span>
        </TableCell>
        {!hideCostType && (
          <TableCell>
            <span styleName="budget-table-cell" />
          </TableCell>
        )}
        <TableCell align="right">
          <span styleName="budget-table-cell budget-total-cell">
            {numberToLocaleString(budgetCategory?.totalBudget)}
          </span>
        </TableCell>
        {showTracking && (
          <>
            <TableCell align="right">
              <span styleName="budget-table-cell budget-total-cell">
                {numberToLocaleString(0)}
              </span>
            </TableCell>
            <TableCell align="right">
              <PercentBar
                value={budgetCategory?.remaining}
                limit={budgetCategory?.totalBudget}
              />
            </TableCell>
          </>
        )}
        <TableCell>
          <span styleName="budget-table-cell" />
        </TableCell>
        <TableCell width={ICON_COL_WIDTH}>
          <span
            ref={anchorRef}
            onClick={handleCompareClick}
            styleName="budget-table-cell clickable"
          >
            {getComparisonIcon(hovering, open)}
          </span>
        </TableCell>
      </TableRow>
      {open && (
        <BudgetDetailComparison
          handleCloseModal={() => setOpenComparisonRowId(null)}
          loadingSummaries={loadingSummaries}
          budgetSummaries={budgetSummaries}
          includeComments
          anchorEl={anchorRef?.current}
          onClickAway={() => setOpenComparisonRowId(null)}
          hideComments
        />
      )}
    </>
  );
};

export default BudgetDetailCategoryRow;
