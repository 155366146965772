import React from 'react';

// @ts-ignore
import compare from '@atom/components/common/svgIcons/compare.svg';
import { BasicBudget, Budget, BudgetSummary } from '@atom/types/budget';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { formatDateMoment } from '@atom/utilities/timeUtilities';

import './budgetDetail.css';

export const COST_TYPE_COL_WIDTH = '7rem';
export const BUDGET_COL_WIDTH = '9rem';
export const COMMENT_COL_WIDTH = '18rem';
export const ICON_COL_WIDTH = '4.25rem';
export const POPOVER_HEIGHT = '25rem';
export const COMPARE_MENU_HEIGHT = '17rem';
export const CATEGORY_EXPAND_LIMIT = 10;
export const DEBOUNCE_TIME = 500;
export const MAX_ITEM_VALUE = {
  ERROR: 'The maximum budget item value is $999,999,999,999.99.',
  VALUE: 999999999999.99,
};

// Used to manage budget item virtualization
export const ITEM_ROW_HEIGHT = 3.4375; // 55px in rem
export const MAX_ROWS_PER_CATEGORY = 10;

export enum EditField {
  VALUE = 'VALUE',
  COMMENT = 'COMMENT',
}

export const FIELD_KEYS = {
  VALUE: 'budgetValue',
  COMMENT: 'comment',
};

export const FIELD_NAMES = {
  VALUE: 'budget',
  COMMENT: 'comment',
};

const formatDate = (millis: number) => formatDateMoment(millis, 'MM/DD/YYYY');

export const getDateRange = (
  budget: BasicBudget | BudgetSummary | Budget,
): string =>
  ` (${formatDate(budget?.startDate?.valueOf())} - ${formatDate(
    budget?.endDate?.valueOf(),
  )})`;

export const getComparisonIcon = (hovering: boolean, open: boolean) => (
  <img
    style={{
      padding: '0 1.5rem',
      visibility: hovering || open ? 'visible' : 'hidden',
    }}
    src={compare}
  />
);

export const EmptyComparisonChart = (
  <div styleName="comparison-none-selected">
    No comparison budgets selected. Select budgets from the Compare menu to
    compare this data across budgets.
  </div>
);

// TODO: Remove exception logic to mark a specific budget as read-only
// https://sadasystems.atlassian.net/browse/AM-15294
const ALDOT_READ_ONLY_BUDGETS: string[] = [
  '66e9fdffa16e966a5fd051bf',
  '66fc4f2e16f68006f34a2f4d',
  '66fc4f47ea35437eadd27ab6',
];

export const isReadOnly = (budgetId: string): boolean =>
  !hasRolePermissions(ROLE_SETS.ADMIN) &&
  ALDOT_READ_ONLY_BUDGETS.includes(budgetId);
